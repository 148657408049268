/* open-sans-regular - latin_cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./statics/fonts/open-sans-v23-latin_cyrillic-regular.eot");
  src: local("Open Sans"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-regular.woff2") format("woff2"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-regular.woff") format("woff"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-regular.ttf") format("truetype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-regular.svg#OpenSans") format("svg");
}
/* open-sans-600 - latin_cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("./statics/fonts/open-sans-v23-latin_cyrillic-600.eot");
  src: local("Open Sans"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-600.eot?#iefix") format("embedded-opentype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-600.woff2") format("woff2"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-600.woff") format("woff"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-600.ttf") format("truetype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-600.svg#OpenSans") format("svg");
}
/* open-sans-700 - latin_cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./statics/fonts/open-sans-v23-latin_cyrillic-700.eot");
  src: local("Open Sans"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-700.woff2") format("woff2"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-700.woff") format("woff"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-700.ttf") format("truetype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-700.svg#OpenSans") format("svg");
}
/* open-sans-800 - latin_cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("./statics/fonts/open-sans-v23-latin_cyrillic-800.eot");
  src: local("Open Sans"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-800.eot?#iefix") format("embedded-opentype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-800.woff2") format("woff2"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-800.woff") format("woff"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-800.ttf") format("truetype"),
  url("./statics/fonts/open-sans-v23-latin_cyrillic-800.svg#OpenSans") format("svg");
}

body {
  margin: 0;
  padding: 0;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
}

body.html2canvas-processing {
  overflow: hidden;
}

body.carrotquest-hidden #carrotquest-messenger-collapsed-container {
  display: none;
}

[id*=cloudpayments-loading-overlay] {
  display: none;
}

.carrotquest-css-reset #carrotquest-messenger-collapsed-container {
  z-index: 1299 !important;
}

@media all and (max-width: 767.95px) {
  .carrotquest-css-reset #bubble-container {
    display: none;
  }

  .carrotquest-css-reset #carrotquest-messenger-collapsed-container {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px !important;
  }
}
