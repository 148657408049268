.slick-slider {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}

.slick-list {
  flex-grow: 1;
}

.slick-track {
  display: flex;
  align-items: stretch;
}

.slick-prev,
.slick-next {
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  background: #55ade0;
  color: #ffffff;
  border: 1px solid transparent;
  position: relative;
  border-radius: 50%;
  transition: background-color 150ms;
  cursor: pointer;
  box-shadow: 0 4px 10px #00000029;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #46a0d4;
}

.slick-prev .icon,
.slick-next .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 20px;
  height: 20px;
}

.slick-prev {
  margin-right: 10px;
}

.slick-next {
  margin-left: 10px;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  cursor: pointer;
  pointer-events: none;
  color: #E2E2E2;
  border-color: currentColor;
  background: transparent;
  box-shadow: none;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-dots {
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;
  margin: 20px auto 0;
  width: auto;
  padding: 0;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin: 0 6px;
}

.slick-dots li button {
  font-size: 0;
  border: none;
  background: #e0e0e0;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  outline: none;
}

.slick-dots li.slick-active button {
  background: #3f51b5;
}

.slick-slider_mobile .slick-list {
  margin: 0;
}

.slick-slide {
  height: auto;
}

.slick-slide > div {
  height: 100%;
}
