.rccs__card--front,
.rccs__card--back {
  box-shadow: none;
}

.rccs__chip {
  display: none;
}

.rccs__card--yoo_money .rccs__card__background {
  background: linear-gradient(25deg, #9f69e2, #7135bc);
}

.rccs__issuer--other {
  color: #ffffff;
  text-transform: uppercase;
  text-align: right;
}